.blockreassurance {
  padding-top: 10px;
  text-align: center;
  width: 100%; }
  .blockreassurance .reass-item {
    display: inline-block;
    text-align: center;
    vertical-align: top; }
  .blockreassurance .block-title {
    font-weight: bold; }
  .blockreassurance .block-icon,
  .blockreassurance .block-icon img,
  .blockreassurance .block-icon svg {
    height: 70px; }

@media (max-width: 575px) {
  .blockreassurance {
    text-align: left; }
  .blockreassurance .block-icon {
    display: inline-block;
    height: 35px;
    float: left;
    margin: 10px;
    width: 35px; }
  .blockreassurance .block-icon img,
  .blockreassurance .block-icon svg {
    height: 35px;
    width: 35px; }
  .blockreassurance .block-title {
    display: inline-block;
    height: 35px;
    max-width: calc(100% - 55px);
    padding-top: 10px; } }

.blockreassurance_product {
  margin-top: 25px; }
  .blockreassurance_product .item-product {
    display: inline-block;
    height: 35px;
    float: left;
    margin-right: 5px;
    padding-top: 5px;
    width: 35px; }

.blockreassurance_product .item-product img,
.blockreassurance_product .item-product svg {
  height: 35px;
  width: 35px; }

.blockreassurance_product p.block-title {
  line-height: 40px; }

/* Specific Checkout */
body#checkout .container-blockreassurance {
  min-height: auto; }
